import React from 'react';
import {
  toast
} from 'react-toastify';
import Toast from "../components/Toast";
import {
  SHOW_TOAST
} from '../constants/actionTypes';
const initialState = {};
const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      {
        toast.info( < Toast type = {
            action.payload.type
          }
          notification = {
            action.payload.notification
          }
          data = {
            action.payload.data
          }
          />, {
          className: 'toast-modify'
        });
      return {
        ...state
      };
  }
  default:
  return state;
}
};
export default toastReducer;