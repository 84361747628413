import { fromJS } from "immutable";
import { SHOW_ALERT, HIDE_ALERT } from "../../constants/actionTypes";
const initialState = fromJS({
  alertConfig: {
    mode: "",
    messages: "",
    show: false
  },
  data: {},
  callbackType: ""
});
const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT: {
      return state
        .set("alertConfig", fromJS(action.payload.alertConfig))
        .set("data", fromJS(action.payload.data))
        .set("callbackType", action.payload.callbackType);
    }
    case HIDE_ALERT: {
      return state
        .set("alertConfig", initialState.get("alertConfig"))
        .set("data", initialState.get("data"))
        .set("callbackType", initialState.get("callbackType"));
    }
    default:
      return state;
  }
};
export default alertReducer;
