import {
  fromJS
} from "immutable";
import {
  GET_TOP_PRODUCT_SUCCES
} from "../../../constants/actionTypes";
const initialState = fromJS({
  topProduct: {
    data: [],
    total: 0
  }
});
const topProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_PRODUCT_SUCCES: {
      return state
        .setIn(["topProduct", "data"], fromJS(action.payload.data))
        .setIn(["topProduct", "total"], action.payload.total);
    }
    default:
      return state;
  }
};
export default topProductReducer;