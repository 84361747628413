import React, { PureComponent } from "react";
import { Badge } from "reactstrap";
import { connect } from "react-redux";
import firebase from "firebase";
import { Link, withRouter } from "react-router-dom";
import ArrowIcon from "../../../components/ArrowIcon";
import Collapse from "../../../components/Collapse";
import Avartar from "../../../components/Avartar";
import { getItemStorage } from "../../../utils/localStore";
import { askForPermissioToReceiveNotifications } from "../../../push-notification";
import { reduxAction } from "../../../actions";
import {
  SUBSCRIBE_TOPIC,
  SHOW_TOAST,
  GET_VIEW_COUNT_ORDER_PENDDING,
  GET_LIST_ORDER_PENDDING
} from "../../../constants/actionTypes";
import "./style.css";
const listMenuUserManager = [
  {
    name: "Đơn hàng",
    src: "order.png",
    id: 1,
    path: "/quan-ly/don-hang/tao-don-hang",
    menuChildren: [
      {
        name: "Tạo đơn hàng",
        src: "create.png",
        path: "/quan-ly/don-hang/tao-don-hang",
        id: 11
      },
      {
        name: "Danh sách đơn hàng",
        src: "list.png",
        path: "/quan-ly/don-hang/danh-sach-don-hang",
        id: 12
      },
      {
        name: "Danh sách công nợ",
        src: "list.png",
        path: "/quan-ly/don-hang/danh-sach-cong-no",
        id: 13
      }
    ]
  },
  {
    name: "Khách hàng",
    src: "custommer.png",
    id: 2,
    path: "/quan-ly/khach-hang/danh-sach-khach-hang",
    menuChildren: [
      {
        name: "Danh sách khách hàng",
        src: "list.png",
        path: "/quan-ly/khach-hang/danh-sach-khach-hang",
        id: 21
      }
    ]
  },
  {
    name: "Cửa hàng",
    src: "supermarket.png",
    id: 3,
    path: "/quan-ly/cuu-hang/nha-cung-cap",
    menuChildren: [
      {
        name: "Nhà cung cấp",
        src: "service.png",
        path: "/quan-ly/cuu-hang/nha-cung-cap",
        id: 31
      },
      {
        name: "Đơn hàng chờ duyệt",
        src: "order.png",
        path: "/quan-ly/cuu-hang/don-hang-cho-duyet",
        id: 32
      },
      {
        name: "Xoá thuốc",
        src: "list.png",
        path: "/quan-ly/cuu-hang/xoa-thuoc",
        id: 33
      },
      {
        name: "Thuốc tạm xóa",
        src: "temp-delete.png",
        path: "/quan-ly/cuu-hang/thuoc-tam-xoa",
        id: 34
      },
      {
        name: "Hàng trong kho",
        src: "list.png",
        path: "/quan-ly/cuu-hang/hang-trong-kho",
        id: 35
      }
    ]
  },
  {
    name: "Thống kê",
    src: "stadistics.png",
    id: 4,
    path: "/quan-ly/thong-ke/top-san-pham",
    menuChildren: [
      {
        name: "Top sản phẩm",
        src: "order.png",
        path: "/quan-ly/thong-ke/top-san-pham",
        id: 41
      }
    ]
  }
];
const listMenuGuest = [
  {
    name: "Đặt hàng",
    src: "order.png",
    id: 0,
    path: "/guest/danh-sach-cua-hang",
    menuChildren: [
      {
        name: "Danh sách cửa hàng",
        src: "supermarket.png",
        path: "/guest/danh-sach-cua-hang",
        id: 1
      }
    ]
  }
];

const adminMenu = {
  name: "Quản trị",
  src: "admin.png",
  id: 6,
  path: "/quan-tri/danh-sach-cua-hang",
  menuChildren: [
    {
      name: "Danh sách cửa hàng",
      src: "list.png",
      path: "/quan-tri/danh-sach-cua-hang",
      id: 61
    },
    {
      name: "Thuốc chờ xác nhận",
      src: "pedding.png",
      path: "/quan-tri/thuoc-cho-xac-nhan",
      id: 62
    }
  ]
};

class LeftSideBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsePath: null
    };
  }
  componentDidMount() {
    if (
      getItemStorage("localStorage", "isAuthenticated") &&
      !this.props.isSubscribe
    ) {
      window.addEventListener("load", this.handlePushNoti);
      this.props.getViewCountOrderPendding();
      setTimeout(async () => {
        const token = await askForPermissioToReceiveNotifications();
        this.props.subscribeToppic(
          {
            token,
            topic: "public-order"
          },
          1000
        );
      });
    }
  }
  handlePushNoti = () => {
    const messaging = firebase.messaging();

    messaging.onMessage(payload => {
      this.props.showToast({
        type: "info",
        notification: payload.notification,
        data: payload.data
      });
      this.props.getViewCountOrderPendding();
      this.props.getListOrderPending({ type: "public" });
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.collapsePath) {
      return {
        collapsePath: nextProps.location.pathname
      };
    }
    return null;
  }

  _handleBntToogle = item => {
    if (this.state.collapsePath === item.path) {
      this.setState({
        collapsePath: null
      });
    } else {
      this.setState({
        collapsePath: item.path
      });
    }
  };
  getMenuUserRoll = role => {
    if (role === "Admin") {
      const menus = [...listMenuUserManager, adminMenu];
      return menus;
    }
    if (role === "Customer") {
      return listMenuGuest;
    }
    return listMenuUserManager;
  };
  render() {
    const { userProfile, viewCount } = this.props;
    const { collapsePath } = this.state;
    const menuList = this.getMenuUserRoll(
      getItemStorage("localStorage", "role")
    );

    return (
      <div className="left-side-bar">
        <div className="left-side-bar__avartar">
          <Avartar />
          <div className="left-side-bar__avartar__text">
            <h5>{userProfile.name}</h5>
            <span>
              <Link to="/quan-tri/tai-khoan/ho-so">Đổi mật khẩu</Link>
            </span>
          </div>
        </div>

        {menuList.map(item => {
          const flags = collapsePath === item.path;
          return (
            <React.Fragment key={item.id}>
              <div
                className={`left-side-bar__item ${
                  collapsePath === item.path ? "bnt-active" : ""
                } `}
                onClick={() => this._handleBntToogle(item)}
              >
                <div>
                  <div className="left-side-bar__item-wrap">
                    <img
                      src={require(`../../../assets/images/${item.src}`)}
                      alt={item.name}
                    />

                    <div className="left-side-bar__item-wrap__text">
                      <div>{item.name}</div>
                      <ArrowIcon
                        up={flags}
                        className={`${flags ? "active" : ""}`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collapse isOpen={collapsePath === item.path}>
                {item.menuChildren.map(item => {
                  return (
                    <div key={item.path}>
                      {item.id === 32 && (
                        <div className="menu-badge">
                          <Badge color="warning" pill>
                            {viewCount.read}
                          </Badge>
                          <Badge color="danger" pill>
                            {viewCount.unread}
                          </Badge>
                        </div>
                      )}
                      <div className="collapse-menu">
                        <img
                          src={require(`../../../assets/images/${item.src}`)}
                          alt={item.name}
                        />

                        <Link to={item.path}>{item.name}</Link>
                      </div>
                    </div>
                  );
                })}
              </Collapse>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userRole: state.get("userRoleReducer").get("role"),
    userProfile: state.get("loginReducer").get("userProfile"),
    isSubscribe: state.get("loginReducer").get("isSubscribe"),
    viewCount: state
      .get("orderPeddingReducer")
      .get("viewCount")
      .toJS()
  };
};
const mapDispatchToProps = dispatch => {
  return {
    subscribeToppic: payload => {
      dispatch(reduxAction(SUBSCRIBE_TOPIC, payload));
    },
    showToast: payload => {
      dispatch(reduxAction(SHOW_TOAST, payload));
    },
    getViewCountOrderPendding: payload => {
      dispatch(reduxAction(GET_VIEW_COUNT_ORDER_PENDDING, payload));
    },
    getListOrderPending: params => {
      dispatch(reduxAction(GET_LIST_ORDER_PENDDING, params));
    }
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeftSideBar)
);
