import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../actions";

import {
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAILURE,
  ADD_CUSTOMER,
  GET_INFO_ORDER_CUSTOMER_BY_ID,
  GET_INFO_ORDER_CUSTOMER_BY_ID_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  DELETE_CUSTOMER,
  // DELETE_CUSTOMER_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT
} from "../../constants/actionTypes";
import apiCaller from "../../api/requestApi";

function* getListCustomerBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.get("/customer", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_CUSTOMER_LIST_SUCCESS, data.data));
    }
  } catch (err) {
    yield put(reduxAction(GET_CUSTOMER_LIST_FAILURE, err));
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* addCustomerBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.post("/customer", action.payload);
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Thêm khách hàng thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_CUSTOMER_LIST));
      // yield put(reduxAction(ADD_CUSTOMER_SUCCESS, data.data));
    }
  } catch (err) {

    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Thêm khách hàng thất bại`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    yield put(reduxAction(ADD_CUSTOMER_FAILURE, err));
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* deleteCustomer(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.delete("/customer", action.payload);
    if (data.status) {

      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Xoá khách hàng thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_CUSTOMER_LIST));
    }
  } catch (err) {

    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Xoá khách hàng thất bại`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    console.log("Xoá khách hàng thất bại", err);
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* getOrderByCustomerId(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));
    const data = yield apiCaller.get(`/order/${action.payload}`);
    if (data.status) {
      yield put(reduxAction(GET_INFO_ORDER_CUSTOMER_BY_ID_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get order by customer id  err", err);
    // yield put(reduxAction(CREATE_NEW_ORDER_FAILURE, err));
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchGetCustomer() {
  yield takeLatest(GET_CUSTOMER_LIST, getListCustomerBySaga);
  yield takeLatest(ADD_CUSTOMER, addCustomerBySaga);
  yield takeLatest(DELETE_CUSTOMER, deleteCustomer);
  yield takeLatest(GET_INFO_ORDER_CUSTOMER_BY_ID, getOrderByCustomerId);
}