import {
  all
} from "redux-saga/effects";
import {
  watchSagaLogin
} from "../containers/Login/saga";
import {
  watchSagaResetPassWord
} from "../containers/ResetPassword/saga";
import {
  watchGetCustomer
} from "../containers/Customer/saga";
import {
  watchAdmin
} from "../containers/Admin/saga";
import {
  watchCustomerSuggest
} from "../containers/CustomerSuggest/saga";
import {
  watchDrugSuggest
} from "../containers/DrugAutoSuggest/saga";
import {
  watchOrderList
} from "../containers/Order/OrderList/saga";
import {
  watchCreateOrder
} from "../containers/Order/CreateOrder/saga";
import {
  watchDebtList
} from "../containers/Order/DebtList/saga";
import {
  watchSupplierList
} from "../containers/Store/Supplier/saga";
import {
  watchDeleteDrug
} from "../containers/Store/DeleteDrug/saga";
import {
  watchDeleteDrugTemp
} from "../containers/Store/DugTempDelete/saga";
import {
  watchApprovalOrderPendding
} from "../containers/Store/OrdersWaitingApproval/saga";
import {
  watchDrugPendding
} from "../containers/Admin/DrugPeddingConfirm/saga";
import {
  watchGoodInStock
} from "../containers/Store/GoodInStock/saga";
import {
  watchTopProduct
} from "../containers/Statistical/TopProduct/saga";
import {
  watchPublicListStore
} from "../containers/Guest/ListStore/saga";
import {
  watchGuestOrderNow
} from "../containers/Guest/OrderNow/saga";
export default function* rootSaga() {
  yield all([
    watchSagaLogin(),
    watchSagaResetPassWord(),
    watchGetCustomer(),
    watchAdmin(),
    watchCreateOrder(),
    watchDrugSuggest(),
    watchCustomerSuggest(),
    watchOrderList(),
    watchDebtList(),
    watchSupplierList(),
    watchDeleteDrug(),
    watchDeleteDrugTemp(),
    watchApprovalOrderPendding(),
    watchDrugPendding(),
    watchGoodInStock(),
    watchTopProduct(),
    watchPublicListStore(),
    watchGuestOrderNow()
  ]);
}