import {
  createSelector
} from "reselect";

const getStateIsAuthenticated = state => {
  return state.get("isAuthenticated");
};

export const isAuthenticatedSelector = createSelector(
  getStateIsAuthenticated,
  isAuthenticated => {
    return isAuthenticated;
  }
);