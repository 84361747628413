import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_DRUG_PEDDING,
  GET_LIST_DRUG_PEDDING_SUCCESS,
  DELETE_DRUG_PEDDING,
  APPROVAL_DRUG_PEDDING,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListDrugsPedding(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/store/medicine/inactive", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_DRUG_PEDDING_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get list drugs pendding err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* deleteDrugPendding(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put("/store/medicine/multi/lock", action.payload);
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Xoá thuốc thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_LIST_DRUG_PEDDING));
    }
  } catch (err) {
    console.log("delete drug err", err);
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Xoá thuốc hàng thất bại! ${err.data.message}`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* approvalDrugPendding(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put("/admin/store/medicine/multi/active", action.payload);
    if (data.status) {

      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Xác nhận thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_LIST_DRUG_PEDDING));
    }
  } catch (err) {
    console.log("delete drug err", err);
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Xác nhận thất bại!  ${err.data.message}`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchDrugPendding() {
  yield takeLatest(GET_LIST_DRUG_PEDDING, getListDrugsPedding);
  yield takeLatest(DELETE_DRUG_PEDDING, deleteDrugPendding);
  yield takeLatest(APPROVAL_DRUG_PEDDING, approvalDrugPendding);

}