//LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
//PROFILE
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
//LOGOUT
export const LOGOUT = "LOGOUT";
//REGISTER
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
//FORGOT PASSWORD
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
//CHANGE PASSWORD
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
//USER ROLE
export const USER_ROLE = "USER_ROLE";
//LOADING
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
//ALERT
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
//SET TAB ACTIVE
export const SET_TAB_ACTIVE = "SET_TAB_ACTIVE";
//RESET PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
//CUSTOMER LIST
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";
//ADD CUSTOMER
export const HANDLE_CHANGE_CUSTOMER_DATA = "HANDLE_CHANGE_CUSTOMER_DATA";
export const HANDLE_CHANGE_CUSTOMER_OTHER_DATA =
  "HANDLE_CHANGE_CUSTOMER_OTHER_DATA";
export const HANDLE_SET_CUSTOMER_DATA = "HANDLE_SET_CUSTOMER_DATA";
export const HANDLE_CHANGE_ORDER_FORM = "HANDLE_CHANGE_ORDER_FORM";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";
export const RESET_DATA_FORM_ORDER = "RESET_DATA_FORM_ORDER";

//DELETE CUSTOMER
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";
//ADMIN

//GET LIST STORE
export const GET_LIST_STORE = "GET_LIST_STORE";
export const GET_LIST_STORE_SUCCESS = "GET_LIST_STORE_SUCCESS";
export const GET_LIST_STORE_FAILURE = "GET_LIST_STORE_FAILURE";

//UPDATE STORE
export const UPDATE_STATUS_STORE = "UPDATE_STATUS_STORE";
export const UPDATE_STATUS_STORE_SUCCESS = "UPDATE_STATUS_STORE_SUCCESS";

//GET LIST SUPPLIER
export const GET_LIST_SUPPLIER = "GET_LIST_SUPPLIER";
export const GET_LIST_SUPPLIER_SUCCESS = "GET_LIST_SUPPLIER_SUCCESS";
export const GET_LIST_SUPPLIER_FAILURE = "GET_LIST_SUPPLIER_FAILURE";

//CREATE SUPPLIER
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAILURE = "CREATE_SUPPLIER_FAILURE";
export const TOOGLE_MODAL_CREATE_SUPPLIER = "TOOGLE_MODAL_CREATE_SUPPLIER";

//GET DRUGS SUGGEST
export const GET_LIST_DRUG_SUGGEST = "GET_LIST_DRUG_SUGGEST";
export const GET_LIST_DRUG_SUGGEST_SUCCESS = "GET_LIST_DRUG_SUGGEST_SUCCESS";
export const GET_LIST_DRUG_SUGGEST_FAILURE = "GET_LIST_DRUG_SUGGEST_FAILURE";
//CREATE DRUGS
export const CREATE_NEW_DRUG = "CREATE_NEW_DRUG";
export const CREATE_NEW_DRUG_SUCCESS = "CREATE_NEW_DRUG_SUCCESS";
export const CREATE_NEW_DRUG_FAILURE = "CREATE_NEW_DRUG_FAILURE";
//ADD,EDIT, REMOVE DRUGS CUSTOMER ORDER AT LOCAL
export const ADD_NEW_DRUG = "ADD_NEW_DRUG";
export const REMOVE_NEW_DRUG = "REMOVE_NEW_DRUG";
export const EDIT_NEW_DRUG = "EDIT_NEW_DRUG";
export const GET_DRUG = "GET_DRUG";
export const EDIT_DRUG_DATA_FIELD = "EDIT_DRUG_DATA_FIELD";

//GET LIST ORDER
export const GET_LIST_ORDER = "GET_LIST_ORDER";
export const GET_LIST_ORDER_SUCCESS = "GET_LIST_ORDER_SUCCESS";
export const GET_LIST_ORDER_FAILURE = "GET_LIST_ORDER_FAILURE";
export const VIEW_ORDER_DETAIL = "VIEW_ORDER_DETAIL";
export const HIDE_MODAL_ORDER_DETAIL = "HIDE_MODAL_ORDER_DETAIL";

//CREATE NEW ORDER
export const CREATE_NEW_ORDER = "CREATE_NEW_ORDER";
export const CREATE_NEW_ORDER_SUCCESS = "CREATE_NEW_ORDER_SUCCESS";
export const CREATE_NEW_ORDER_FAILURE = "CREATE_NEW_ORDER_FAILURE";

export const HIDE_MODAL_ORDER_INFO = "HIDE_MODAL_ORDER_INFO";
export const HIDE_MODAL_EDIT_DRUG = "HIDE_MODAL_EDIT_DRUG";
//GET CUSTOMER SUGGEST
export const GET_CUSTOMER_NAME_SUGGEST = "GET_CUSTOMER_NAME_SUGGEST";
export const GET_CUSTOMER_NAME_SUGGEST_SUCCESS =
  "GET_CUSTOMER_NAME_SUGGEST_SUCCESS";
export const GET_CUSTOMER_NAME_SUGGEST_FAILURE =
  "GET_CUSTOMER_NAME_SUGGEST_FAILURE";
//CREATE NEW CUSTOMER
export const CREATE_NEW_CUSTOMER = "CREATE_NEW_CUSTOMER";
export const CREATE_NEW_CUSTOMER_SUCCESS = "CREATE_NEW_CUSTOMER_SUCCESS";
export const CREATE_NEW_CUSTOMER_FAILURE = "CREATE_NEW_CUSTOMER_FAILURE";
//GET LIST ORDER BY ID
export const GET_LIST_ORDER_BY_ID = "GET_LIST_ORDER_BY_ID";
export const GET_LIST_ORDER_BY_ID_SUCCESS = "GET_LIST_ORDER_BY_ID_SUCCESS";
export const GET_LIST_ORDER_BY_ID_FAILURE = "GET_LIST_ORDER_BY_ID_FAILURE";

//SHOW INFO CUSTOMER
export const SHOW_MODAL_INFO_CUSTOMER = "SHOW_INFO_CUSTOMER";
export const HIDE_MODAL_INFO_CUSTOMER = "HIDE_MODAL_INFO_CUSTOMER";

//GET INFO ORDER CUSTOMER BY ID
export const GET_INFO_ORDER_CUSTOMER_BY_ID = "GET_INFO_ORDER_CUSTOMER_BY_ID";
export const GET_INFO_ORDER_CUSTOMER_BY_ID_SUCCESS =
  "GET_INFO_ORDER_CUSTOMER_BY_ID_SUCCESS";
export const GET_INFO_ORDER_CUSTOMER_BY_ID_FAILURE =
  "GET_INFO_ORDER_CUSTOMER_BY_ID_FAILURE";

//GET LIST DEBT
export const GET_LIST_DEBT = "GET_LIST_DEBT";
export const GET_LIST_DEBT_SUCCESS = "GET_LIST_DEBT_SUCCESS";
export const GET_LIST_DEBT_FAILURE = "GET_LIST_DEBT_FAILURE";
//EDIT DEBT
export const EDIT_DEBT = "EDIT_DEBT";
export const EDIT_DEBT_SUCCESS = "EDIT_DEBT_SUCCESS";
export const EDIT_DEBT_FAILURE = "EDIT_DEBT_FAILURE";
export const SHOW_MODAL_EDIT_DEBT = "SHOW_MODAL_EDIT_DEBT";
export const HIDE_MODAL_EDIT_DEBT = "HIDE_MODAL_EDIT_DEBT";

//GET LIST DRUG
export const GET_LIST_DRUG = "GET_LIST_DRUG";
export const GET_LIST_DRUG_SUCCESS = "GET_LIST_DRUG_SUCCESS";

//DELETE DRUG
export const DELETE_DRUG = "DELETE_DRUG";
export const DELETE_DRUG_SUCCESS = "DELETE_DRUG_SUCCESS";

//GET LIST DRUG DELETE TEMP
export const GET_LIST_DRUG_DELETE_TEMP = "GET_LIST_DRUG_DELETE_TEMP";
export const GET_LIST_DRUG_DELETE_TEMP_SUCCESS =
  "GET_LIST_DRUG_DELETE_TEMP_SUCCESS";
//DELETE DRUG DELETE TEMP
export const DELETE_DRUG_DELETE_TEMP = "DELETE_DRUG_DELETE_TEMP";
export const DELETE_DRUG_DELETE_TEMP_SUCCESS =
  "DELETE_DRUG_DELETE_TEMP_SUCCESS";
//RESTORE DRUG DELETE TEMP
export const RESTORE_DRUG_DELETE_TEMP = "RESTORE_DRUG_DELETE_TEMP";
export const RESTORE_DRUG_DELETE_TEMP_SUCCESS =
  "RESTORE_DRUG_DELETE_TEMP_SUCCESS";
//ORDER PEDDING
export const GET_LIST_ORDER_PENDDING = "GET_LIST_ORDER_PENDDING";
export const GET_LIST_ORDER_PENDDING_SUCCESS =
  "GET_LIST_ORDER_PENDDING_SUCCESS";
//APPROVAL ORDER PEDDING
export const APPROVAL_ORDER_PEDDING = "APPROVAL_ORDER_PEDDING";
export const APPROVAL_ORDER_PEDDING_SUCCESS = "APPROVAL_ORDER_PEDDING_SUCCESS";
//GET LIST DRUG PEDDING
export const GET_LIST_DRUG_PEDDING = "GET_LIST_DRUG_PEDDING";
export const GET_LIST_DRUG_PEDDING_SUCCESS = "GET_LIST_DRUG_PEDDING_SUCCESS";
//DELETE DRUG PEDDING
export const DELETE_DRUG_PEDDING = "DELETE_DRUG_PEDDING";
export const DELETE_DRUG_PEDDING_SUCCESS = "DELETE_DRUG_PEDDING_SUCCESS";
//APPROVAL DRUG PEDDING
export const APPROVAL_DRUG_PEDDING = "APPROVAL_DRUG_PEDDING";
export const APPROVAL_DRUG_PEDDING_SUCCESS = "APPROVAL_DRUG_PEDDING_SUCCESS";

//UPDATE AMOUNT DRUG
export const UPDATE_AMOUNT_DRUG = "UPDATE_AMOUNT_DRUG";
export const UPDATE_AMOUNT_DRUG_SUCCESS = "UPDATE_AMOUNT_DRUG_SUCCESS";
//GET TOP PRODUCT
export const GET_TOP_PRODUCT = "GET_TOP_PRODUCT";
export const GET_TOP_PRODUCT_SUCCES = "GET_TOP_PRODUCT_SUCCES";

//GET PUBLIC STORE
export const GET_LIST_PUBLIC_STORE = "GET_LIST_PUBLIC_STORE";
export const GET_LIST_PUBLIC_STORE_SUCCES = "GET_LIST_PUBLIC_STORE_SUCCES";
//SET STORE ID
export const SET_STORE_ID = "SET_STORE_ID";
//GUSET ORDER NOW
export const HANDLE_CHANGE_GUEST_DATA = "HANDLE_CHANGE_GUEST_DATA";
export const HANDLE_CHANGE_GUEST_ORTHER_DATA =
  "HANDLE_CHANGE_GUEST_ORTHER_DATA";
export const ADD_DRUG_GUEST_ORDER = "ADD_DRUG_GUEST_ORDER";
export const CREATE_GUEST_ORDER_SUCCESS = "CREATE_GUEST_ORDER_SUCCESS";
export const CREATE_GUEST_ORDER = "CREATE_GUEST_ORDER";
export const REMOVE_DRUG_GUEST_ORDER = "REMOVE_DRUG_GUEST_ORDER";
export const GET_DRUG_GUEST_SELECT = "GET_DRUG_GUEST_SELECT";
export const EDIT_DRUG_GUEST_ORDER = "EDIT_DRUG_GUEST_ORDER";
export const HIDE_MODAL_EDIT_DRUG_BY_GUEST = "HIDE_MODAL_EDIT_DRUG_BY_GUEST";
export const RESET_DATA_FORM_GUEST_ORDER = "RESET_DATA_FORM_GUEST_ORDER";
export const EDIT_DRUG_GUEST_ORDER_DATA_FIELD = "EDIT_DRUG_GUEST_ORDER_DATA_FIELD";

//GET TOP PRODUCT
export const SELECT_DRUG_NEED_IMPORT = "SELECT_DRUG_NEED_IMPORT";
export const SUBSCRIBE_TOPIC = "SUBSCRIBE_TOPIC";
export const SUBSCRIBE_TOPIC_SUCCESS = "SUBSCRIBE_TOPIC_SUCCESS";
export const UNSUBSCRIBE_TOPIC = "UNSUBSCRIBE_TOPIC";
export const SHOW_TOAST = "SHOW_TOAST";
export const UPDATE_VIEW_COUNT_ORDER_PENDDING = "UPDATE_VIEW_COUNT_ORDER_PENDDING";
export const GET_VIEW_COUNT_ORDER_PENDDING = "GET_VIEW_COUNT_ORDER_PENDDING";
export const GET_VIEW_COUNT_ORDER_PENDDING_SUCCESS = "GET_VIEW_COUNT_ORDER_PENDDING_SUCCESS";