import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import HambergerButton from "../../../components/HambergerButton";
import IconText from "../../../components/IconText";

//action
import { reduxAction } from "../../../actions";
//action type
import { LOGOUT, UNSUBSCRIBE_TOPIC } from "../../../constants/actionTypes";
import { removeAllLocal, getItemStorage } from "../../../utils/localStore";
import "./style.css";

class Header extends React.PureComponent {
  _handleLogout = () => {
    if (getItemStorage("localStorage", "isAuthenticated")) {
      this.props.unsubscriebToppic({
        token: this.props.deviceToken,
        topic: "public-order"
      });
    }
    removeAllLocal();
    this.props._handleLogout();

    // this.props.history.push("/dang-nhap");
  };
  render() {
    return (
      <div className="header-wrap">
        <div className="header-left">
          <div className="header-left-icon">
            <HambergerButton onClick={this.props.onClick} />
          </div>
          <div className="header-left-homepage">
            <Link to="/">PPMS.VN</Link>
          </div>
        </div>
        {/* {ROLE.ADMIN === this.props.userRole && (
          <div className="header-right" onClick={this._handleLogout}>
            <IconText icon={<FaSignOutAlt />} text="Thoát" />
          </div>
        )} */}
        <div className="header-right" onClick={this._handleLogout}>
          <IconText icon={<FaSignOutAlt />} text="Thoát" />
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    _handleLogout: () => {
      dispatch(reduxAction(LOGOUT));
    },
    unsubscriebToppic: payload => {
      dispatch(reduxAction(UNSUBSCRIBE_TOPIC, payload));
    }
  };
};
const mapStateToProps = state => {
  return {
    userRole: state.get("userRoleReducer").get("role"),
    deviceToken: state.get("userRoleReducer").get("deviceToken")
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
