import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_TOP_PRODUCT,
  GET_TOP_PRODUCT_SUCCES,
  SHOW_LOADING,
  HIDE_LOADING
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getTopProduct(action) {
  try {
    yield put(reduxAction(SHOW_LOADING))
    const data = yield apiCaller.get("/report/medicine", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_TOP_PRODUCT_SUCCES, data.data));
    }
  } catch (err) {
    console.log("lấy danh sách top sản phẩm thất bai");
  } finally {
    yield put(reduxAction(HIDE_LOADING))
  }
}

export function* watchTopProduct() {
  yield takeLatest(GET_TOP_PRODUCT, getTopProduct);

}