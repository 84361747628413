import {
  fromJS
} from "immutable";
import {
  GET_CUSTOMER_NAME_SUGGEST_SUCCESS
} from "../../constants/actionTypes"
const initialState = fromJS({
  customerSuggest: []
});
const customerSuggestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_NAME_SUGGEST_SUCCESS:
      {
        return state.set("customerSuggest", fromJS(action.payload.data))
      }
    default:
      return state;
  }
};
export default customerSuggestReducer;