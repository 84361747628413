import { put, takeLatest } from "redux-saga/effects";

import { reduxAction } from "../../../actions";

import {
  CREATE_NEW_ORDER,
  CREATE_NEW_ORDER_SUCCESS,
  CREATE_NEW_ORDER_FAILURE,
  CREATE_NEW_DRUG,
  ADD_NEW_DRUG,
  CREATE_NEW_DRUG_FAILURE,
  SHOW_LOADING,
  HIDE_LOADING,
  GET_LIST_ORDER_BY_ID,
  GET_LIST_ORDER_BY_ID_SUCCESS,
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* creatNewDrug(action) {
  try {
    const data = yield apiCaller.post("/store/medicine", action.payload);
    if (data.status) {
      yield put(reduxAction(ADD_NEW_DRUG, data.data));
    }
  } catch (err) {
    console.log("create new drugs err", err);
    yield put(reduxAction(CREATE_NEW_DRUG_FAILURE, err));
  }
}

function* creatNewOrder(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));
    let data;
    const { orderId } = action.payload;
    const isCopy =
      action.payload.data &&
      action.payload.data.customer &&
      action.payload.data.customer.isCopy;
    if (orderId && !isCopy) {
      data = yield apiCaller.put(`/order/${orderId}`, action.payload.data);
    } else {
      data = yield apiCaller.post("/order", action.payload.data);
    }
    if (data.status) {
      yield put(reduxAction(CREATE_NEW_ORDER_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("create new order err", err);
    yield put(reduxAction(CREATE_NEW_ORDER_FAILURE, err));
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* getOrderById(action) {
  try {
    const data = yield apiCaller.get(`/order/${action.payload}`);
    if (data.status) {
      yield put(reduxAction(GET_LIST_ORDER_BY_ID_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get order by id err", err);
  }
}
export function* watchCreateOrder() {
  yield takeLatest(CREATE_NEW_DRUG, creatNewDrug);
  yield takeLatest(CREATE_NEW_ORDER, creatNewOrder);
  yield takeLatest(GET_LIST_ORDER_BY_ID, getOrderById);
}
