import {
  fromJS
} from "immutable";
import {
  HANDLE_CHANGE_GUEST_DATA,
  HANDLE_CHANGE_GUEST_ORTHER_DATA,
  ADD_DRUG_GUEST_ORDER,
  REMOVE_DRUG_GUEST_ORDER,
  GET_DRUG_GUEST_SELECT,
  HIDE_MODAL_EDIT_DRUG_BY_GUEST,
  EDIT_DRUG_GUEST_ORDER,
  RESET_DATA_FORM_GUEST_ORDER,
  EDIT_DRUG_GUEST_ORDER_DATA_FIELD
} from "../../../constants/actionTypes";
const initialState = fromJS({
  customerFormData: {
    customer: {
      name: "",
      phone: "",
      address: "",
      note: "",
      email: ""
    },
    deliveryDate: null,
  },
  orderFormData: {
    name: "",
    amount: "",
    price: ""
  },
  drugsCustomerOrder: [],
  drug: {
    _id: null,
    name: "",
    amount: "",
    price: ""
  },
  showModalEdit: false,
  showModalInfo: false
});
const guestOrderNowReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE_GUEST_DATA:
      {
        return state.setIn(
          ["customerFormData", "customer", action.payload.name],
          action.payload.value
        );
      }
    case HANDLE_CHANGE_GUEST_ORTHER_DATA:
      {
        return state.setIn(
          ["customerFormData", action.payload.name],
          action.payload.value
        );
      }
    case ADD_DRUG_GUEST_ORDER:
      {
        return state
          .set("orderFormData", fromJS(action.payload))
          .updateIn(["drugsCustomerOrder"], drugs =>
            drugs.push(fromJS(action.payload))
          );
      }
    case REMOVE_DRUG_GUEST_ORDER:
      {
        return state.updateIn(["drugsCustomerOrder"], drugs => {
          return drugs.filter((item, index) => {
            return index !== action.payload;
          });
        });
      }
    case GET_DRUG_GUEST_SELECT:
      {
        return state
          .set("drug", fromJS(action.payload))
          .set("showModalEdit", true);
      }
    case HIDE_MODAL_EDIT_DRUG_BY_GUEST:
      {
        return state.set("showModalEdit", false);
      }
    case EDIT_DRUG_GUEST_ORDER_DATA_FIELD:
      {
        return state.setIn(["drug", action.payload.name], action.payload.value);
      }
    case EDIT_DRUG_GUEST_ORDER:
      {
        return state
          .update("drugsCustomerOrder", function (list) {
            let idx = list.findIndex(function (item) {
              return item.get("_id") === action.payload._id;
            });
            return list.setIn([idx], fromJS(action.payload));
          })
          .set("showModalEdit", false);
      }
    case RESET_DATA_FORM_GUEST_ORDER:
      {
        return state
          .set("customerFormData", initialState.get("customerFormData"))
          .set("drugsCustomerOrder", initialState.get("drugsCustomerOrder"));
      }
    default:
      return state;
  }
};
export default guestOrderNowReducer;