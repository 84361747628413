import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../actions";

import {
  GET_LIST_DRUG_SUGGEST,
  GET_LIST_DRUG_SUGGEST_SUCCESS,
  GET_LIST_DRUG_SUGGEST_FAILURE

} from "../../constants/actionTypes";
import apiCaller from "../../api/requestApi";


function* getDrugsSuggest(action) {
  try {
    const data = yield apiCaller.get("/store/medicine/active", action.payload);
    if (data.status) {
      if (data.data.data.length > 0) {
        yield put(reduxAction(GET_LIST_DRUG_SUGGEST_SUCCESS, data.data));

      } else {
        const data = [{
          isAddNew: true
        }]
        yield put(reduxAction(GET_LIST_DRUG_SUGGEST_SUCCESS, {
          data
        }));

      }
    }
  } catch (err) {
    console.log("get drugs suggest err", err);
    yield put(reduxAction(GET_LIST_DRUG_SUGGEST_FAILURE, err));
  }
}

export function* watchDrugSuggest() {
  yield takeLatest(GET_LIST_DRUG_SUGGEST, getDrugsSuggest);
}