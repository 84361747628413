import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_PUBLIC_STORE,
  GET_LIST_PUBLIC_STORE_SUCCES,
  SHOW_LOADING,
  HIDE_LOADING
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListPublicStore(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/public/store", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_PUBLIC_STORE_SUCCES, data.data));
    }
  } catch (err) {
    console.log("get list public store err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

export function* watchPublicListStore() {
  yield takeLatest(GET_LIST_PUBLIC_STORE, getListPublicStore);
  // yield takeLatest(CREATE_SUPPLIER, createSupplier);

}