export const setItemStorage = (key, name, value) => {
  if (window[key]) {
    window[key].setItem(name, value);
  } else {
    console.log("ls does not exist");
  }
};
export const getItemStorage = (key, name) => {
  if (window[key]) {
    return window[key].getItem(name);
  } else {
    console.log("ls does not exist");
  }
};
export const removeItemStorage = (key, name) => {
  if (window[key]) {
    return window[key].removeItem(name);
  } else {
    console.log("ls does not exist");
  }
};

export const removeAllLocal = () => {
  const dataInLocal = [
    "accessToken",
    "isAuthenticated",
    "refreshToken",
    "rememberLogin",
    "storeId",
    "role"
  ];
  dataInLocal.forEach(item => {
    removeItemStorage("localStorage", item);
  });
}