import {
  createSelector
} from "reselect";

const getAlertConfig = state => {
  return state.get("alertConfig");
};
const getDataViaAlert = state => {
  return state.get("data");
};
const getCallbackType = state => {
  return state.get("callbackType");
};
export const makeSelectAlertConfig = createSelector(
  getAlertConfig,
  alertConfig => {
    return alertConfig.toJS();
  }
);
export const makeSelectDataViaAlert = createSelector(
  getDataViaAlert,
  data => {
    return data.toJS();
  }
);
export const makeSelectCallbackType = createSelector(
  getCallbackType,
  callbackType => {
    return callbackType;
  }
);