import React, { PureComponent } from "react";
import LeftSideBar from "./LeftSideBar";

import Header from "./Header";
import "./style.css";

class DashBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toogle: false
    };
    this._toogleLeftSidebar = toogle => {
      this.setState({
        toogle
      });
    };
  }

  render() {
    return (
      <section className="app-wrapper">
        <header className="app-header">
          <div className="app-header-icon">
            <Header onClick={() => this._toogleLeftSidebar(true)} />
          </div>
        </header>
        <div
          className={`app-nav-left ${this.state.toogle ? "toogle" : ""}`}
          onMouseEnter={() => this._toogleLeftSidebar(true)}
          onMouseLeave={() => this._toogleLeftSidebar(false)}
        >
          <LeftSideBar />
        </div>
        <main className="app-main">
          <div
            className={`app-overlay  ${this.state.toogle ? "show" : ""}`}
            onClick={() => this._toogleLeftSidebar(false)}
          />
          <div className="app-main-content">{this.props.children}</div>
        </main>
      </section>
    );
  }
}
export default DashBoard;
