import { put, takeLatest } from "redux-saga/effects";

import { reduxAction } from "../../../actions";

import {
  GET_LIST_ORDER_PENDDING,
  GET_LIST_ORDER_PENDDING_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING,
  GET_VIEW_COUNT_ORDER_PENDDING,
  GET_VIEW_COUNT_ORDER_PENDDING_SUCCESS,
  UPDATE_VIEW_COUNT_ORDER_PENDDING,
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListOrderPendding(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/order", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_ORDER_PENDDING_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get list order pendding err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* getViewCountOrderPendding(action) {
  try {
    const data = yield apiCaller.get("/order/read", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_VIEW_COUNT_ORDER_PENDDING_SUCCESS, data));
    }
  } catch (err) {
    console.log("get view count order pendding err", err);
  }
}

function* updateViewCountOrderPendding(action) {
  try {
    const data = yield apiCaller.post(`/order/read/${action.payload}`);
    if (data.status) {
      yield put(reduxAction(GET_VIEW_COUNT_ORDER_PENDDING));
    }
  } catch (err) {
    console.log("get view count order pendding err", err);
  }
}
export function* watchApprovalOrderPendding() {
  yield takeLatest(GET_LIST_ORDER_PENDDING, getListOrderPendding);
  yield takeLatest(GET_VIEW_COUNT_ORDER_PENDDING, getViewCountOrderPendding);
  yield takeLatest(
    UPDATE_VIEW_COUNT_ORDER_PENDDING,
    updateViewCountOrderPendding
  );
}
