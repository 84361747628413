import {
  combineReducers
} from "redux-immutable";
import {
  connectRouter
} from "connected-react-router/immutable";
import history from "../configs/history";

import loginReducer from "../containers/Login/reducer";
import loadingReducer from "../containers/Loading/reducer";
import customerReducer from "../containers/Customer/reducer";
import adminReducer from "../containers/Admin/reducer";
// import orderReducer from "../containers/Order/reducer";
import drugSuggestReducer from "../containers/DrugAutoSuggest/reducer";
import customerSuggestReducer from "../containers/CustomerSuggest/reducer";
import orderListReducer from "../containers/Order/OrderList/reducer";
import createOrderReducer from "../containers/Order/CreateOrder/reducer";
import debtListReducer from "../containers/Order/DebtList/reducer";
import alertReducer from "../components/Alert/reducer";
import supplierReducer from "../containers/Store/Supplier/reducer";
import deleteDrugReducer from "../containers/Store/DeleteDrug/reducer";
import deleteDrugTempReducer from "../containers/Store/DugTempDelete/reducer";
import orderPeddingReducer from "../containers/Store/OrdersWaitingApproval/reducer";
import drugInStockReducer from "../containers/Store/GoodInStock/reducer";
import drugPenddingReducer from "../containers/Admin/DrugPeddingConfirm/reducer";
import topProductReducer from "../containers/Statistical/TopProduct/reducer";
import publicStoreReducer from "../containers/Guest/ListStore/reducer";
import guestOrderNowReducer from "../containers/Guest/OrderNow/reducer";
import toastReducer from "./toastReducer";
import userRoleReducer from "./userRole";
const appReducer = combineReducers({
  router: connectRouter(history),
  loginReducer,
  loadingReducer,
  customerReducer,
  adminReducer,
  createOrderReducer,
  drugSuggestReducer,
  customerSuggestReducer,
  orderListReducer,
  debtListReducer,
  alertReducer,
  supplierReducer,
  deleteDrugReducer,
  deleteDrugTempReducer,
  orderPeddingReducer,
  drugInStockReducer,
  drugPenddingReducer,
  topProductReducer,
  publicStoreReducer,
  userRoleReducer,
  guestOrderNowReducer,
  toastReducer
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;