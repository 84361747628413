import React from "react";
import "./style.css";
const ArrowIcon = React.memo(function ArrowIcon(props) {
  return (
    <div className="arrow-wrap">
      <i className={`arrow ${props.className} ${props.up ? "up" : "down"}`} />
    </div>
  );
});
export default ArrowIcon;
