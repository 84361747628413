import {
  put,
  takeLatest
} from "redux-saga/effects";
import {
  push
} from "connected-react-router";
import {
  setItemStorage,
  removeAllLocal
} from "../../utils/localStore";
import {
  reduxAction
} from "../../actions";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER,
  REGISTER_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  CHANGE_PASSWORD,
  LOGOUT,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT,
  SUBSCRIBE_TOPIC,
  UNSUBSCRIBE_TOPIC,
  SUBSCRIBE_TOPIC_SUCCESS
} from "../../constants/actionTypes";
import apiCaller from "../../api/requestApi";
import {
  ROLE
} from "../../constants/role"

function setUserAuthen(user) {
  setItemStorage("localStorage", "accessToken", user.accessToken);
  setItemStorage("localStorage", "refreshToken", user.refreshToken);
  setItemStorage("localStorage", "isAuthenticated", true);
}

function* loginBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.post("/login", action.payload);
    if (data.status) {
      if (data.data.user.role === ROLE.CUSTOMER) {
        setItemStorage("localStorage", "accessToken", data.data.accessToken);
        setItemStorage("localStorage", "refreshToken", data.data.refreshToken);
        setItemStorage("localStorage", "role", data.data.user.role);

        yield put(push("/guest/danh-sach-cua-hang"));
      } else {
        setUserAuthen(data.data);
        setItemStorage("localStorage", "role", data.data.user.role);
        yield put(reduxAction(LOGIN_SUCCESS, data));
        yield put(reduxAction(GET_USER_PROFILE));
      }
    }
  } catch (err) {
    if (err.data) {
      alert(err.data.message)
    }
    yield put(reduxAction(LOGIN_FAILURE, err));
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* registerBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.post("/register", action.payload);
    if (data.status) {
      // setUserAuthen(data);

      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Đăng ký tài khoản thành công!. Vui lòng chờ xác nhận từ admin",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(push("/dang-nhap"));
      // yield put(reduxAction(REGISTER_SUCCESS, data));
    }
  } catch (err) {
    alert(err.data.message);
    yield put(reduxAction(REGISTER_FAILURE, err));
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* forgotPasswordBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.post("/forgot", action.payload);
    if (data.status) {
      yield put(reduxAction(FORGOT_PASSWORD_SUCCESS, data));
    }
  } catch (err) {
    alert(err.data.message);
    yield put(reduxAction(FORGOT_PASSWORD_FAILURE, err));
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* getUserProfile() {
  try {
    const data = yield apiCaller.get("/profile");
    if (data.status) {
      setItemStorage("localStorage", "storeId", data.data.store);
      yield put(reduxAction(GET_USER_PROFILE_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get user profile err", err);
  }
}

function* changePass(action) {
  try {
    const data = yield apiCaller.post("/account/change-password", action.payload);
    if (data.status) {
      removeAllLocal();
      yield put(reduxAction(LOGOUT));
    }
  } catch (err) {
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Thay đổi mật khẩu thất bại`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    console.log("change pass err", err);
  }
}

function* subscribeToppic(action) {
  try {
    const data = yield apiCaller.post("/subscribled", action.payload);
    if (data.status) {
      yield put(reduxAction(SUBSCRIBE_TOPIC_SUCCESS))
    }
  } catch (err) {
    console.log("subscribeToppic err", err);
  }
}

function* unsubscribeToppic(action) {
  try {
    const data = yield apiCaller.post("/unsubscribled", action.payload);
    if (data.status) {

    }
  } catch (err) {
    console.log("subscribeToppic err", err);
  }
}
export function* watchSagaLogin() {
  yield takeLatest(LOGIN, loginBySaga);
  yield takeLatest(REGISTER, registerBySaga);
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordBySaga);
  yield takeLatest(GET_USER_PROFILE, getUserProfile);
  yield takeLatest(CHANGE_PASSWORD, changePass);
  yield takeLatest(SUBSCRIBE_TOPIC, subscribeToppic);
  yield takeLatest(UNSUBSCRIBE_TOPIC, unsubscribeToppic);

}