import {
  fromJS
} from "immutable";
import {
  USER_ROLE,
  LOGIN_SUCCESS,
  SUBSCRIBE_TOPIC
} from "../constants/actionTypes";
import {
  ROLE
} from "../constants/role";
const initialState = fromJS({
  role: ROLE.ADMIN,
  deviceToken: null
});
const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ROLE:
      {
        return state.set("role", action.payload);
      }
    case LOGIN_SUCCESS:
      {
        return state.set("role", initialState.get("role"));
      }
    case SUBSCRIBE_TOPIC:
      {
        return state.set("deviceToken", action.payload.token)
      }
    default:
      return state;
  }
};
export default userRoleReducer;