import {
  fromJS
} from "immutable";
import {
  GET_LIST_DRUG_SUCCESS,
  GET_LIST_DRUG
} from "../../../constants/actionTypes";
const initialState = fromJS({
  drugs: {
    data: [],
    totalPage: 0
  },
  activePage: 0
});
const deleteDrugReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DRUG_SUCCESS: {
      return state
        .setIn(["drugs", "data"], fromJS(action.payload.data))
        .setIn(["drugs", "totalPage"], action.payload.totalPage);
    }
    case GET_LIST_DRUG: {
      const {
        page
      } = action.payload;
      return state.set("activePage", page ? page - 1 : 0)
    }
    default:
      return state;
  }
};
export default deleteDrugReducer;