import {
  put,
  takeLatest
} from "redux-saga/effects";
import {
  push
} from 'connected-react-router'


// import {
//   setItemStorage
// } from "../../utils/localStore";
// import {
//   reduxAction
// } from "../../actions";

import {
  RESET_PASSWORD,
  SHOW_LOADING,
  HIDE_LOADING
} from "../../constants/actionTypes";
import apiCaller from "../../api/requestApi";


function* resetPasswordBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.post("/reset", action.payload);
    if (data.status) {
      // yield put(reduxAction(FORGOT_PASSWORD_SUCCESS, data));
      alert("tạo lại mật khẩu thành công");
      yield put(push('/dang-nhap'));
    }
  } catch (err) {
    // yield put(reduxAction(FORGOT_PASSWORD_FAILURE, err));
    alert("tạo lại mật khẩu thất bại")
  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

export function* watchSagaResetPassWord() {

  yield takeLatest(RESET_PASSWORD, resetPasswordBySaga);
}