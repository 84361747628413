import React from "react";
import PropTypes from "prop-types";
import "./style.css";
const HambergerButton = React.memo(function HambergerButton(props) {
  return (
    <div
      className={`hamburger ${props.toogle ? "toogle" : ""}`}
      onClick={props.onClick}
    >
      <span className="navicon" />
    </div>
  );
});
HambergerButton.propTypes = {
  toogle: PropTypes.bool,
  onClick: PropTypes.func
};
export default HambergerButton;
