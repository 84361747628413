import React, { memo } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticatedSelector } from "./selector";

const AuthRoute = memo(function AuthRoute({
  isAuthenticated,
  component: Component,
  requiredRole,
  role,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          if (requiredRole) {
            if (requiredRole === role) {
              return <Component {...props} />;
            } else {
              return <Redirect to="/" />;
            }
          } else {
            return <Component {...props} />;
          }
        } else {
          return <Redirect to="/dang-nhap" />;
        }
      }}
    />
  );
});

function mapStateToProps(state) {
  return {
    isAuthenticated: isAuthenticatedSelector(state.get("loginReducer")),
    role: state.get("loginReducer").get("userProfile").role
  };
}

export default connect(mapStateToProps)(AuthRoute);
