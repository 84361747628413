import React, { memo } from "react";
import { Collapse } from "reactstrap";
import "./style.css";
const CollapseButton = memo(function CollapseButton(props) {
  return (
    <Collapse isOpen={props.isOpen}>
      <div>{props.children}</div>
    </Collapse>
  );
});
export default CollapseButton;
