import dayjs from "dayjs";
import {
  type
} from "os";

export const caculatorTotal = (datas = []) => {
  let total = 0;
  datas.forEach(item => {
    total = total + item.amount * item.price;
  });
  return total;
};
export const formatDate = dateStr => {
  if (!dateStr) return null;
  const arr = dateStr.split("/");
  return `${arr[2]}/${arr[1]}/${arr[0]}`;
};
export const frommatDateFromUTC = dateUtc => {
  if (!dateUtc) return null;
  return dayjs(dateUtc).format("DD/MM/YYYY");
};
export const getStatusDebt = status => {
  switch (status) {
    case "NotYet":
      return "Chưa thu";
    case "Expire":
      return "Hết hạn";
    case "Prepare":
      return "Chuẩn bị thu";
    case "Near":
      return "Sắp thu";
    default:
      break;
  }
};
export const getStatusStore = status => {
  switch (status) {
    case "active":
      return "Hoạt động";
    case "inactive":
      return "Đóng cửa";
    default:
      return "Hoạt động";
  }
};
export const formatNumberCurrency = number => {

  if (number) {
    let numberValue = parseFloat(number);
    return numberValue.toLocaleString();
  }
  return 0

};
export const getListIdFromObj = objId => {
  const listId = [];
  Object.entries(objId).forEach(item => {
    if (item[1]) {
      listId.push(item[0]);
    }
  });
  return listId;
};
export const getAmountStatus = (status) => {
  switch (status) {
    case "Immediately":
      return "Nhập hàng gấp";
    case "Prepare":
      return "Sắp hết hàng";
    case "InStock":
      return "Còn hàng";
    default:
      return "Nhập hàng gấp";
  }
}
export const formatCurrencyByRegex = (number = "0") => {
  if (number) {
    let temp = number;
    if (typeof (number) === "number") {
      temp = number.toString();
    }

    const value = temp.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return value
  }
  return ""

}