import React, { memo } from "react";
import "./style.css";
import User from "../../assets/images/user.png";
const Avartar = memo(function Avartar(props) {
  return (
    <div className="avartar">
      <img src={props.src || User} alt="user" className="avartar-img" />
    </div>
  );
});
export default Avartar;
