import {
  fromJS
} from "immutable";

import {
  SHOW_LOADING,
  HIDE_LOADING
} from '../../constants/actionTypes';
const initialState = fromJS({
  isLoading: false
});
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      {
        return state.set("isLoading", true);
      }
    case HIDE_LOADING:
      {
        return state.set("isLoading", false);
      }
    default:
      return state;
  }
};
export default loadingReducer;