import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_DEBT,
  GET_LIST_DEBT_SUCCESS,
  EDIT_DEBT,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListDebt(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/debt", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_DEBT_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("create list debt err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* editDebt(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put(
      `/debt/${action.payload.debtId}`,
      action.payload.data
    );
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Cập nhật công nợ thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_LIST_DEBT));
    }
  } catch (err) {
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Cập nhật công nợ thất bại`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    console.log("edit debt err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchDebtList() {
  yield takeLatest(GET_LIST_DEBT, getListDebt);
  yield takeLatest(EDIT_DEBT, editDebt);
}