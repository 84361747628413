import { createStore, applyMiddleware } from "redux";
import { fromJS } from "immutable";
import { routerMiddleware } from "connected-react-router/immutable";

import { composeWithDevTools } from "redux-devtools-extension";
// import logger from 'redux-logger';
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import createSagaMiddleware from "redux-saga";
const sagaMiddleware = createSagaMiddleware();
const middleware = history => {
  if (process.env.NODE_ENV === "development") {
    return composeWithDevTools(
      applyMiddleware(sagaMiddleware, routerMiddleware(history))
    );
    // return applyMiddleware(sagaMiddleware, routerMiddleware(history), logger);
  }
  return applyMiddleware(sagaMiddleware, routerMiddleware(history));
};

export default function configureStore(initialState, history) {
  const store = createStore(
    rootReducer,
    fromJS(initialState),
    middleware(history)
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
