import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, withRouter, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthRoute from "./containers/Router/AuthRouter";
import UserRoute from "./containers/Router/UserRouter";
import CustomerRoute from "./containers/Router/Customer";
import Loading from "./containers/Loading";
import DashBoard from "./containers/DashBoard";
import PageNotFound from "./components/PageNotFound";
import Alert from "./components/Alert";
import { USER_ROLE, GET_USER_PROFILE } from "./constants/actionTypes";
import { ROLE } from "./constants/role";

import { getItemStorage } from "./utils/localStore";

import { reduxAction } from "./actions";
import "./App.css";

//layzy load component
//MAIN
const Main = lazy(() => import("./containers/Main"));
//LOGIN
const Login = lazy(() => import("./containers/Login"));
const ResetPassword = lazy(() => import("./containers/ResetPassword"));
//ORDER
const Order = lazy(() => import("./containers/Order/CreateOrder"));
const OrderList = lazy(() => import("./containers/Order/OrderList"));
const DebitList = lazy(() => import("./containers/Order/DebtList"));
//CUSTOMER
const Customer = lazy(() => import("./containers/Customer"));
//STORE
const Supplier = lazy(() => import("./containers/Store/Supplier"));
const OrdersWaitingApproval = lazy(() =>
  import("./containers/Store/OrdersWaitingApproval")
);
const DeleteDrug = lazy(() => import("./containers/Store/DeleteDrug"));
const DrugTempDelete = lazy(() => import("./containers/Store/DugTempDelete"));
const GoodInStock = lazy(() => import("./containers/Store/GoodInStock"));

//ACCOUNT
const ProfileSetting = lazy(() => import("./containers/ProfileSetting"));
//STATISTICAL
const TopProduct = lazy(() => import("./containers/Statistical/TopProduct"));
//ADMIN
const ListStore = lazy(() => import("./containers/Admin/ListStore"));
const DrugPenddingConfirm = lazy(() =>
  import("./containers/Admin/DrugPeddingConfirm")
);
// const StoreManager = lazy(() => import("./containers/Admin/StoreManager"));
const GuestPublicStore = lazy(() => import("./containers/Guest/ListStore"));
const GuestOrderNow = lazy(() => import("./containers/Guest/OrderNow"));
const ROUTER = [
  {
    path: "/quan-ly/don-hang/tao-don-hang/:orderId?",
    component: Order,
    exact: true
  },
  {
    path: "/quan-ly/don-hang/danh-sach-don-hang/:customerId?",
    component: OrderList,
    exact: true
  },
  {
    path: "/quan-ly/don-hang/danh-sach-cong-no",
    component: DebitList,
    exact: true
  },
  {
    path: "/quan-ly/khach-hang/danh-sach-khach-hang",
    component: Customer,
    exact: true
  },
  {
    path: "/quan-ly/cuu-hang/nha-cung-cap",
    component: Supplier,
    exact: true
  },
  {
    path: "/quan-ly/cuu-hang/don-hang-cho-duyet",
    component: OrdersWaitingApproval,
    exact: true
  },
  {
    path: "/quan-ly/cuu-hang/xoa-thuoc",
    component: DeleteDrug,
    exact: true
  },
  {
    path: "/quan-ly/cuu-hang/thuoc-tam-xoa",
    component: DrugTempDelete,
    exact: true
  },
  {
    path: "/quan-ly/cuu-hang/hang-trong-kho",
    component: GoodInStock,
    exact: true
  },
  {
    path: "/quan-ly/thong-ke/top-san-pham",
    component: TopProduct,
    exact: true
  },
  {
    path: "/quan-tri/tai-khoan/ho-so",
    component: ProfileSetting,
    exact: true
  },
  {
    path: "/quan-tri/danh-sach-cua-hang",
    component: ListStore,
    exact: true,
    requiredRole: "Admin"
  },
  {
    path: "/quan-tri/thuoc-cho-xac-nhan",
    component: DrugPenddingConfirm,
    exact: true
  }
  // {
  //   path: "/quan-tri/quan-ly-cua-hang",
  //   component: StoreManager,
  //   exact: true
  // }
];
const PUBLIC_ROUTE = [
  {
    path: "/guest/danh-sach-cua-hang",
    component: () => <GuestPublicStore />,
    exact: false
  },
  {
    path: "/guest/dat-hang-ngay/:storeId",
    component: () => <GuestOrderNow />,
    exact: false
  }
];

class App extends Component {
  getUserType = path => {
    const pathToArray = path.split("/");
    if (pathToArray[1] === ROLE.GUSET) return ROLE.GUSET;
    return ROLE.ADMIN;
  };
  componentDidMount() {
    const { location } = this.props;
    const userType = this.getUserType(location.pathname);
    this.props.setUserRole(userType);
    if (getItemStorage("localStorage", "isAuthenticated")) {
      if (userType === ROLE.ADMIN) {
        this.props.getUserProfile();
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <Loading />
        <Alert />
        <ToastContainer
          autoClose={4000}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          hideProgressBar
          position="top-right"
        />
        <Switch>
          <AuthRoute
            path="/"
            exact
            component={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <Main />
              </Suspense>
            )}
          />
          <UserRoute
            path="/dang-nhap"
            exact
            component={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <Login />
              </Suspense>
            )}
          />
          <UserRoute
            path="/tao-lai-mat-khau/:token"
            exact
            component={() => (
              <Suspense fallback={<div>Loading...</div>}>
                <ResetPassword />
              </Suspense>
            )}
          />

          <Route
            path="/khong-tim-thay-trang"
            component={() => {
              return <PageNotFound />;
            }}
            exact
          />
          <DashBoard>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {ROUTER.map((route, index) => {
                  return (
                    <AuthRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                      requiredRole={route.requiredRole}
                    />
                  );
                })}
                {PUBLIC_ROUTE.map((route, index) => {
                  return (
                    <CustomerRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                })}
                <Route
                  path="*"
                  render={() => {
                    return <Redirect to="/khong-tim-thay-trang" />;
                  }}
                />
              </Switch>
            </Suspense>
          </DashBoard>
        </Switch>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setUserRole: role => {
      dispatch(reduxAction(USER_ROLE, role));
    },
    getUserProfile: () => {
      dispatch(reduxAction(GET_USER_PROFILE));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(App)
);
