import { fromJS } from "immutable";
import dayjs from "dayjs";
import { frommatDateFromUTC, caculatorTotal } from "../../../utils";
import {
  HANDLE_CHANGE_CUSTOMER_DATA,
  HANDLE_CHANGE_CUSTOMER_OTHER_DATA,
  HANDLE_SET_CUSTOMER_DATA,
  ADD_NEW_DRUG,
  CREATE_NEW_ORDER_SUCCESS,
  CREATE_NEW_ORDER_FAILURE,
  HIDE_MODAL_ORDER_INFO,
  REMOVE_NEW_DRUG,
  GET_DRUG,
  EDIT_DRUG_DATA_FIELD,
  HIDE_MODAL_EDIT_DRUG,
  EDIT_NEW_DRUG,
  GET_LIST_ORDER_BY_ID_SUCCESS,
  RESET_DATA_FORM_ORDER,
} from "../../../constants/actionTypes";
const caculatorRemainDebt = (state, listDrugs) => {
  return (
    caculatorTotal(listDrugs.toJS()) -
    +state.getIn(["customerFormData", "paid"])
  );
};
const initialState = fromJS({
  customerFormData: {
    customer: {
      _id: null,
      name: "",
      phone: "",
      address: "",
      note: "",
      email: "",
    },
    deliveryDate: dayjs(new Date()).format("DD/MM/YYYY"),
    paid: "",
    remainDebt: "",
    expireDate: null,
  },
  orderFormData: {
    name: "",
    amount: "",
    price: "",
  },
  drugsCustomerOrder: [],
  drug: {
    _id: null,
    name: "",
    amount: "",
    price: "",
  },
  showModalEdit: false,
  showModalInfo: false,
});
const createOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE_CUSTOMER_DATA: {
      return state.setIn(
        ["customerFormData", "customer", action.payload.name],
        action.payload.value
      );
    }
    case HANDLE_CHANGE_CUSTOMER_OTHER_DATA: {
      if (action.payload.name === "paid") {
        const drugsCustomerOrder = state.get("drugsCustomerOrder");
        const remainDebt =
          caculatorTotal(drugsCustomerOrder.toJS()) - +action.payload.value;
        return state
          .setIn(
            ["customerFormData", "remainDebt"],
            remainDebt > 0 ? remainDebt : "0"
          )
          .setIn(
            ["customerFormData", action.payload.name],
            action.payload.value
          );
      }
      return state.setIn(
        ["customerFormData", action.payload.name],
        action.payload.value
      );
    }
    case HANDLE_SET_CUSTOMER_DATA: {
      return state.setIn(["customerFormData", "customer"], action.payload);
    }
    case ADD_NEW_DRUG: {
      //get list drugs
      const listDrugs = state
        .updateIn(["drugsCustomerOrder"], (drugs) =>
          drugs.push(fromJS(action.payload))
        )
        .get("drugsCustomerOrder");
      //get paid

      const remainDebt = caculatorRemainDebt(state, listDrugs);
      return state
        .set("orderFormData", fromJS(action.payload))
        .set("drugsCustomerOrder", listDrugs)
        .setIn(["customerFormData", "remainDebt"], remainDebt);
    }
    case REMOVE_NEW_DRUG: {
      //get list drugs
      const listDrugs = state
        .updateIn(["drugsCustomerOrder"], (drugs) => {
          return drugs.filter((item, index) => {
            return index !== action.payload;
          });
        })
        .get("drugsCustomerOrder");
      //get paid
      const remainDebt = caculatorRemainDebt(state, listDrugs);
      return state
        .set("drugsCustomerOrder", listDrugs)
        .setIn(["customerFormData", "remainDebt"], remainDebt);
    }
    case GET_DRUG: {
      return state
        .set("drug", fromJS(action.payload))
        .set("showModalEdit", true);
    }
    case HIDE_MODAL_EDIT_DRUG: {
      return state.set("showModalEdit", false);
    }
    case CREATE_NEW_ORDER_SUCCESS: {
      alert("Thực hiện thành công");
      return state.set("showModalInfo", true);
    }
    case CREATE_NEW_ORDER_FAILURE: {
      alert("Tạo đơn hàng thất bại");
      return state;
    }
    case HIDE_MODAL_ORDER_INFO: {
      return state
        .set("showModalInfo", false)
        .set("customerFormData", initialState.get("customerFormData"))
        .set("drugsCustomerOrder", initialState.get("drugsCustomerOrder"));
    }
    case EDIT_NEW_DRUG: {
      //get list drugs
      const listDrugs = state
        .update("drugsCustomerOrder", function (list) {
          let idx = list.findIndex(function (item) {
            return item.get("_id") === action.payload._id;
          });
          return list.setIn([idx], fromJS(action.payload));
        })
        .get("drugsCustomerOrder");
      //get paid
      const paid = state.getIn(["customerFormData", "paid"]);
      //caculator remainDebt
      const remainDebt = caculatorTotal(listDrugs.toJS()) - +paid;

      return state
        .set("drugsCustomerOrder", listDrugs)
        .setIn(["customerFormData", "remainDebt"], remainDebt)
        .set("showModalEdit", false);
    }
    case EDIT_DRUG_DATA_FIELD: {
      return state.setIn(["drug", action.payload.name], action.payload.value);
    }
    case GET_LIST_ORDER_BY_ID_SUCCESS: {
      return state
        .setIn(
          ["customerFormData", "customer"],
          fromJS(action.payload.customer)
        )
        .setIn(
          ["customerFormData", "deliveryDate"],
          frommatDateFromUTC(action.payload.deliveryDate) || null
        )
        .setIn(
          ["customerFormData", "expireDate"],
          frommatDateFromUTC(action.payload.expireDate) || null
        )
        .setIn(
          ["customerFormData", "remainDebt"],
          action.payload.remainDebt || ""
        )
        .setIn(["customerFormData", "paid"], action.payload.paid || "")
        .set("drugsCustomerOrder", fromJS(action.payload.medicines));
    }
    case RESET_DATA_FORM_ORDER: {
      return state
        .set("customerFormData", initialState.get("customerFormData"))
        .set("drugsCustomerOrder", initialState.get("drugsCustomerOrder"));
    }
    default:
      return state;
  }
};
export default createOrderReducer;
