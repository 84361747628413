import React, { memo } from "react";
import { Route, Redirect } from "react-router-dom";
import { getItemStorage } from "../../utils/localStore";

const CustomerRoute = memo(function AuthRoute({
  component: Component,
  ...rest
}) {
  const role = getItemStorage("localStorage", "role");
  return (
    <Route
      {...rest}
      render={props =>
        role === "Customer" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dang-nhap" />
        )
      }
    />
  );
});

export default CustomerRoute;
