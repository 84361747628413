import {
  createSelector
} from "reselect";

const loadingSelector = state => {
  return state.get("isLoading");
};

export const getLoadingSelector = createSelector(
  loadingSelector,
  isLoading => {
    return isLoading;
  }
);