import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  CREATE_GUEST_ORDER,
  RESET_DATA_FORM_GUEST_ORDER,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* creatNewOrder(action) {
  try {
    yield put(reduxAction(SHOW_LOADING))
    const data = yield apiCaller.post("/public/order", action.payload);

    if (data.status) {

      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Đặt hàng thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(RESET_DATA_FORM_GUEST_ORDER));
    }
  } catch (err) {
    console.log("create new order err", err);
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: "Đặt hàng thất bại",
        show: true
      },
      data: {},
      callbackType: ""

    }))
  } finally {
    yield put(reduxAction(HIDE_LOADING))


  }
}
export function* watchGuestOrderNow() {
  yield takeLatest(CREATE_GUEST_ORDER, creatNewOrder);
}