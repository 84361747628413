import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_ORDER,
  GET_LIST_ORDER_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING

} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListOrder(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/order", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_ORDER_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("create list order err", err);

  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchOrderList() {
  yield takeLatest(GET_LIST_ORDER, getListOrder);

}