import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";
import {
  getItemStorage
} from "../../../utils/localStore"
import {
  UPDATE_AMOUNT_DRUG,
  GET_LIST_DRUG,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* updateAmountDrug(action) {
  try {
    const {
      amountAdd,
      supplier
    } = action.payload;
    yield put(reduxAction(SHOW_LOADING))
    const data = yield apiCaller.put(`/store/medicine/${action.payload._id}`, {
      amount: amountAdd,
      supplier: supplier._id ? supplier._id : null
    });
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Cập nhật số lượng thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))

      yield put(reduxAction(GET_LIST_DRUG, {
        store: getItemStorage("localStorage", "storeId")
      }));
    }
  } catch (err) {
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `cập nhật số lượng thất bại `,
        show: true
      },
      data: {},
      callbackType: ""

    }))
  } finally {
    yield put(reduxAction(HIDE_LOADING))
  }
}

export function* watchGoodInStock() {
  yield takeLatest(UPDATE_AMOUNT_DRUG, updateAmountDrug);

}