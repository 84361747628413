import React, { memo } from "react";
import { connect } from "react-redux";

import { getLoadingSelector } from "./selector";
import "./style.css";
const Loading = memo(function Loading(props) {
  return props.isLoading ? (
    <div className="loading-wrapper">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : (
    ""
  );
});
function mapStateToProps(state) {
  return {
    isLoading: getLoadingSelector(state.get("loadingReducer"))
  };
}
export default connect(mapStateToProps)(Loading);
