import {
  fromJS
} from "immutable";
import {
  GET_LIST_SUPPLIER_SUCCESS,
  TOOGLE_MODAL_CREATE_SUPPLIER,
  GET_LIST_SUPPLIER
} from "../../../constants/actionTypes";
const initialState = fromJS({
  suppliers: {
    data: [],
    totalPage: 0
  },
  openModal: false,
  activePage: 0
});
const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_SUPPLIER_SUCCESS: {
      return state
        .setIn(["suppliers", "data"], fromJS(action.payload.data))
        .setIn(["suppliers", "totalPage"], action.payload.totalPage);
    }
    case TOOGLE_MODAL_CREATE_SUPPLIER: {
      return state.set("openModal", action.payload)
    }
    case GET_LIST_SUPPLIER: {
      const {
        page
      } = action.payload;
      return state.set("activePage", page ? page - 1 : 0)
    }
    default:
      return state;
  }
};
export default supplierReducer;