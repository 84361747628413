import {
  fromJS
} from "immutable";

import {
  GET_LIST_STORE_SUCCESS
} from "../../constants/actionTypes"
const initialState = fromJS({
  stores: {
    data: [],
    totalPage: 0
  }
});
const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_STORE_SUCCESS:
      {
        return state.setIn(["stores", "data"], action.payload.data).setIn(["stores", "totalPage"], action.payload.totalPage)
      }
    default:
      return state;
  }
};
export default adminReducer;