import {
  fromJS
} from "immutable";
import {
  GET_CUSTOMER_LIST_SUCCESS,
  GET_INFO_ORDER_CUSTOMER_BY_ID_SUCCESS,
  HIDE_MODAL_INFO_CUSTOMER
} from "../../constants/actionTypes"
const initialState = fromJS({
  customerList: {
    data: [],
    totalPage: 0
  },
  customerInfo: {},
  showModal: false
});
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST_SUCCESS:
      {
        return state.setIn(["customerList", "data"], fromJS(action.payload.data)).setIn(["customerList", "totalPage"], action.payload.totalPage)
      }
    case GET_INFO_ORDER_CUSTOMER_BY_ID_SUCCESS:
      {
        return state.set("showModal", true)
      }
    case HIDE_MODAL_INFO_CUSTOMER:
      {
        return state.set("showModal", false)
      }
    default:
      return state;
  }
};
export default customerReducer;