import {
  fromJS
} from "immutable";
import {
  GET_LIST_ORDER_SUCCESS,
  VIEW_ORDER_DETAIL,
  HIDE_MODAL_ORDER_DETAIL,
  GET_LIST_ORDER
} from "../../../constants/actionTypes"
const initialState = fromJS({
  orderList: {
    data: [],
    totalPage: 0
  },
  orderDetail: {
    customer: {},
    medicines: [],
    total: 0,
    deliveryDate: ""
  },
  showModalViewDetail: false,
  activePage: 0
});
const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_ORDER_SUCCESS: {
      return state.setIn(["orderList", "data"], fromJS(action.payload.data)).setIn(["orderList", "totalPage"], action.payload.totalPage)
    }
    case VIEW_ORDER_DETAIL: {
      return state.set("orderDetail", fromJS(action.payload)).set("showModalViewDetail", true)
    }
    case HIDE_MODAL_ORDER_DETAIL: {
      return state.set("showModalViewDetail", false)
    }
    case GET_LIST_ORDER: {
      const {
        page
      } = action.payload;
      return state.set("activePage", page ? page - 1 : 0)
    }

    default:
      return state;
  }
};
export default orderListReducer;