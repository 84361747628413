import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_SUPPLIER,
  GET_LIST_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAILURE,
  SHOW_LOADING,
  HIDE_LOADING
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListSupplier(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/supplier", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_SUPPLIER_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get list supplier err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* createSupplier(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.post("/supplier", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_SUPPLIER));
    }
  } catch (err) {
    console.log("get list supplier err", err);
    alert("Thêm mới nhà cung cấp thất bại")
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchSupplierList() {
  yield takeLatest(GET_LIST_SUPPLIER, getListSupplier);
  yield takeLatest(CREATE_SUPPLIER, createSupplier);

}