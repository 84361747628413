import React, { memo } from "react";

import "./style.css";
const IconText = memo(function(props) {
  return (
    <React.Fragment>
      <span>{props.icon}</span>
      <span className="icon-text"> {props.text}</span>
    </React.Fragment>
  );
});

export default IconText;
