import {
  put,
  takeLatest,
  takeEvery
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_DRUG,
  GET_LIST_DRUG_SUCCESS,
  DELETE_DRUG,
  SHOW_ALERT,
  SHOW_LOADING,
  HIDE_LOADING
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";
import {
  getItemStorage
} from "../../../utils/localStore"

function* getListDrugs(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));
    console.log("-->", action.payload)
    const data = yield apiCaller.get("/store/medicine/active", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_DRUG_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get list drugs err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* deleteDrug(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put("/store/medicine/multi/delete", action.payload);
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Xoá thuốc thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_LIST_DRUG, {
        store: getItemStorage("localStorage", "storeId")
      }));
    }
  } catch (err) {
    console.log("delete drug err", err);
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Xoá thuốc thất bại! ${err.data.message}`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchDeleteDrug() {
  yield takeLatest(GET_LIST_DRUG, getListDrugs);
  yield takeLatest(DELETE_DRUG, deleteDrug);
  yield takeEvery('*', function* logger(action) {})

}