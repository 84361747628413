import axios from "axios";
import {
  getItemStorage
} from "../utils/localStore"

const METHOD_GET = "GET";
const METHOD_POST = "POST";
const METHOD_PUT = "PUT";
const METHOD_DELETE = "DELETE";
async function requestAPI(method, url, headers = {}, dataBody) {
  headers["Content-Type"] = "application/json";
  headers["accept"] = "application/json";
  headers['Authorization'] = getItemStorage("localStorage", "accessToken");
  const config = {
    baseURL: process.env.REACT_APP_DOMAIN,
    url: url,
    headers: headers,
    method: method
  };

  if (method === METHOD_GET) {
    config.params = dataBody;
  } else {
    config.data = dataBody;
  }

  return axios(config)
    .then(async res => {
      return res.data;
    })
    .catch(err => {
      return new Promise(function (resolve, reject) {
        if (err.response) {
          return reject(err.response);
        }
        return reject(err);
      });
    });
}
export default {
  get(url, dataBody, headers = {}) {
    return requestAPI(METHOD_GET, url, headers, dataBody);
  },

  post(url, dataBody, headers = {}) {
    return requestAPI(METHOD_POST, url, headers, dataBody);
  },

  put(url, dataBody, headers = {}) {
    return requestAPI(METHOD_PUT, url, headers, dataBody);
  },

  delete(url, dataBody, headers = {}) {
    return requestAPI(METHOD_DELETE, url, headers, dataBody);
  }
};