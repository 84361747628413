import {
  fromJS
} from "immutable";

import {
  getItemStorage,
  removeItemStorage
} from "../../utils/localStore";

import {
  LOGIN_SUCCESS,
  LOGOUT,
  GET_USER_PROFILE_SUCCESS,
  SET_STORE_ID,
  SUBSCRIBE_TOPIC_SUCCESS
} from "../../constants/actionTypes";
if (!getItemStorage("localStorage", "rememberLogin")) {
  removeItemStorage("localStorage", "accessToken");
  removeItemStorage("localStorage", "refreshToken");
  removeItemStorage("localStorage", "isAuthenticated");
}
const initialState = fromJS({
  isAuthenticated: Boolean(getItemStorage("localStorage", "isAuthenticated")) || false,
  userProfile: {},
  storeId: null,
  isSubscribe: false
});
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      {
        return state.set("isAuthenticated", true);
      }
    case LOGOUT:
      {
        return state.set("isAuthenticated", false);
      }
    case GET_USER_PROFILE_SUCCESS:

      {
        return state.set("userProfile", action.payload).set("storeId", action.payload.store)
      }
    case SET_STORE_ID:
      {
        return state.set("storeId", action.payload)
      }
    case SUBSCRIBE_TOPIC_SUCCESS:
      return state.set("isSubscribe", true)
    default:
      return state;
  }
};
export default loginReducer;