import React, { PureComponent } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import { HIDE_ALERT } from "../../constants/actionTypes";

import { reduxAction } from "../../actions";

import {
  makeSelectAlertConfig,
  makeSelectDataViaAlert,
  makeSelectCallbackType
} from "./selector";
class Alert extends PureComponent {
  onCancel = () => {
    this.props.handleHideAlert();
  };
  onConfirm = () => {
    const { data, callbackType } = this.props;
    this.props.handleHideAlert();
    this.props.handleConfirm(callbackType, data);
  };
  render() {
    const { alertConfig } = this.props;
    const { show, mode, messages } = alertConfig;
    if (!show) return null;
    switch (mode) {
      case "info":
        return (
          <SweetAlert
            info
            confirmBtnText="Xác nhận"
            cancelBtnText="Huỷ"
            confirmBtnBsStyle="info"
            title={messages}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          />
        );
      case "success":
        return (
          <SweetAlert
            success
            confirmBtnText="Đóng"
            confirmBtnBsStyle="success"
            title={messages}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            timeout={1000}
          />
        );
      case "warning":
        return (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Xác nhận"
            cancelBtnText="Huỷ"
            confirmBtnBsStyle="warning"
            cancelBtnBsStyle="primary"
            title="Bạn có chắc?"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          >
            {messages}
          </SweetAlert>
        );
      case "danger":
        return (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Xoá"
            cancelBtnText="Huỷ"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="primary"
            title={messages}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          />
        );
      case "error":
        return (
          <SweetAlert
            danger
            confirmBtnText="Đóng"
            confirmBtnBsStyle="danger"
            title={messages}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          />
        );
      default:
        return (
          <SweetAlert
            default
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title={messages}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          />
        );
    }
  }
}
const mapStateToProps = state => {
  return {
    alertConfig: makeSelectAlertConfig(state.get("alertReducer")),
    data: makeSelectDataViaAlert(state.get("alertReducer")),
    callbackType: makeSelectCallbackType(state.get("alertReducer"))
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleConfirm: (actionType, data) => {
      dispatch(reduxAction(actionType, data));
    },
    handleHideAlert: () => {
      dispatch(reduxAction(HIDE_ALERT));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
