import {
  fromJS
} from "immutable";
import {
  GET_LIST_ORDER_PENDDING_SUCCESS,
  GET_VIEW_COUNT_ORDER_PENDDING_SUCCESS
} from "../../../constants/actionTypes";
const initialState = fromJS({
  orderPeddings: {
    data: [],
    totalPage: 0
  },
  viewCount: {
    read: 0,
    unread: 0
  }
});
const orderPeddingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_ORDER_PENDDING_SUCCESS:
      {
        return state
          .setIn(["orderPeddings", "data"], fromJS(action.payload.data))
          .setIn(["orderPeddings", "totalPage"], action.payload.totalPage);
      }
    case GET_VIEW_COUNT_ORDER_PENDDING_SUCCESS:
      {
        return state.set("viewCount", fromJS(action.payload.data))
      }
    default:
      return state;
  }
};
export default orderPeddingReducer;