import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { formatCurrencyByRegex } from "../../utils";
// import sucessIcon from "../../assets/image/checked.png";
// import ErrorIcon from "../../assets/image/cancel.png";
import "./style.css";
export class Toast extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Link to="/quan-ly/cuu-hang/don-hang-cho-duyet" className="toast-noti">
        <div className="toast-noti-icon">
          {/* <img
            src={`${type === "success" ? sucessIcon : ErrorIcon} `}
            alt="success"
          /> */}
        </div>
        <div className="toast-noti-content">
          <div>Đơn hàng mới</div>
          <div>Tên khách hàng: {data["gcm.notification.name"]}</div>
          <div>
            Tổng tiền: {formatCurrencyByRegex(data["gcm.notification.total"])}{" "}
            VNĐ
          </div>
          <div />
        </div>
      </Link>
    );
  }
}

export default Toast;
