import * as firebase from 'firebase';
export const initializeFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      messagingSenderId: "888546966895"
    });
  }

}
export const askForPermissioToReceiveNotifications = async () => {
  try {
    // debugger;
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    // subscriberToTopic(token,'some-topic');
    return token;
  } catch (error) {
    console.error(error);
  }
}