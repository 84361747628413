import {
  fromJS
} from "immutable";
import {
  GET_LIST_DRUG_DELETE_TEMP_SUCCESS
} from "../../../constants/actionTypes";
const initialState = fromJS({
  drugsDeleteTemp: {
    data: [],
    totalPage: 0
  }
});
const deleteDrugTempReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DRUG_DELETE_TEMP_SUCCESS:
      {
        return state
          .setIn(["drugsDeleteTemp", "data"], fromJS(action.payload.data))
          .setIn(["drugsDeleteTemp", "totalPage"], action.payload.totalPage);
      }
    default:
      return state;
  }
};
export default deleteDrugTempReducer;