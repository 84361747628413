import {
  fromJS
} from "immutable";
import {
  GET_LIST_DEBT_SUCCESS,
  SHOW_MODAL_EDIT_DEBT,
  HIDE_MODAL_EDIT_DEBT,
  EDIT_DEBT,
  GET_LIST_DEBT
} from "../../../constants/actionTypes";
const initialState = fromJS({
  debts: {
    data: [],
    totalPage: 0
  },
  debt: {},
  showModal: false,
  activePage: 0
});
const debtListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DEBT_SUCCESS: {
      return state
        .setIn(["debts", "data"], fromJS(action.payload.data))
        .setIn(["debts", "totalPage"], action.payload.totalPage);
    }
    case SHOW_MODAL_EDIT_DEBT: {
      return state
        .set("debt", fromJS(action.payload))
        .set("showModal", true);
    }
    case HIDE_MODAL_EDIT_DEBT: {
      return state.set("showModal", false)
    }
    case EDIT_DEBT: {
      return state.set("debt", fromJS({}))
    }
    case GET_LIST_DEBT: {
      const {
        page
      } = action.payload;
      return state.set("activePage", page ? page - 1 : 0)
    }

    default:
      return state;
  }
};
export default debtListReducer;