import {
  fromJS
} from "immutable";

import {
  GET_LIST_DRUG_PEDDING_SUCCESS,

} from "../../../constants/actionTypes"
const initialState = fromJS({
  drugsPendding: {
    data: [],
    totalPage: 0
  }
});
const drugPenddingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DRUG_PEDDING_SUCCESS:
      {
        return state.setIn(["drugsPendding", "data"], fromJS(action.payload.data)).setIn(["drugsPendding", "totalPage"], action.payload.totalPage)
      }
    default:
      return state;
  }
};
export default drugPenddingReducer;