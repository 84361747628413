import { fromJS } from "immutable";
import { GET_LIST_DRUG_SUGGEST_SUCCESS } from "../../constants/actionTypes";
const initialState = fromJS({
  drugsSuggest: [],
});
const drugSuggestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DRUG_SUGGEST_SUCCESS: {
      return state.set("drugsSuggest", fromJS(action.payload.data));
    }
    default:
      return state;
  }
};
export default drugSuggestReducer;
