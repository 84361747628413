import {
  put,
  takeLatest
} from "redux-saga/effects";
import {
  reduxAction
} from "../../actions";
import {
  GET_LIST_STORE,
  GET_LIST_STORE_SUCCESS,
  GET_LIST_STORE_FAILURE,
  SHOW_ALERT,
  UPDATE_STATUS_STORE,
  SHOW_LOADING,
  HIDE_LOADING

} from "../../constants/actionTypes";
import apiCaller from "../../api/requestApi";


function* getListStoreBySaga(action) {
  try {
    yield put({
      type: SHOW_LOADING
    });
    const data = yield apiCaller.get("/account/manager", action.payload);
    if (data.status) {

      yield put(reduxAction(GET_LIST_STORE_SUCCESS, data.data));
    }
  } catch (err) {
    yield put(reduxAction(GET_LIST_STORE_FAILURE, err));

  } finally {
    yield put({
      type: HIDE_LOADING
    });
  }
}

function* updateStatusStore(action) {
  const status = action.payload.status === "active" ? "inactive" : "active"
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put(`/store/${action.payload.store}/change/${status}`);
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: `${status} cửa hàng thành công`,
          show: true
        },
        data: {},
        callbackType: ""

      }))

      yield put(reduxAction(GET_LIST_STORE));
    }
  } catch (err) {
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `${status} cửa hàng thất bại! ${err.data.message}`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    console.log("delete drug err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchAdmin() {
  yield takeLatest(GET_LIST_STORE, getListStoreBySaga);
  yield takeLatest(UPDATE_STATUS_STORE, updateStatusStore);
}