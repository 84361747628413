import {
  put,
  takeLatest
} from "redux-saga/effects";

import {
  reduxAction
} from "../../../actions";

import {
  GET_LIST_DRUG_DELETE_TEMP,
  GET_LIST_DRUG_DELETE_TEMP_SUCCESS,
  DELETE_DRUG_DELETE_TEMP,
  RESTORE_DRUG_DELETE_TEMP,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_ALERT
} from "../../../constants/actionTypes";
import apiCaller from "../../../api/requestApi";

function* getListDrugsDeleteTemp(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.get("/store/medicine/delete", action.payload);
    if (data.status) {
      yield put(reduxAction(GET_LIST_DRUG_DELETE_TEMP_SUCCESS, data.data));
    }
  } catch (err) {
    console.log("get list drugs delete temp err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* deleteDrugTemp(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put("/store/medicine/multi/lock", action.payload);
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Xoá thuốc thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))
      yield put(reduxAction(GET_LIST_DRUG_DELETE_TEMP));
    }
  } catch (err) {
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Xoá thuốc thất bại `,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    console.log("delete drug err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}

function* restoreDrugTemp(action) {
  try {
    yield put(reduxAction(SHOW_LOADING));

    const data = yield apiCaller.put("/store/medicine/multi/active", action.payload);
    if (data.status) {
      yield put(reduxAction(SHOW_ALERT, {
        alertConfig: {
          mode: "success",
          messages: "Khôi phục thuốc thành công",
          show: true
        },
        data: {},
        callbackType: ""

      }))

      yield put(reduxAction(GET_LIST_DRUG_DELETE_TEMP));
    }
  } catch (err) {
    yield put(reduxAction(SHOW_ALERT, {
      alertConfig: {
        mode: "error",
        messages: `Khôi phục thuốc thất bại`,
        show: true
      },
      data: {},
      callbackType: ""

    }))
    console.log("delete drug err", err);
  } finally {
    yield put(reduxAction(HIDE_LOADING));
  }
}
export function* watchDeleteDrugTemp() {
  yield takeLatest(GET_LIST_DRUG_DELETE_TEMP, getListDrugsDeleteTemp);
  yield takeLatest(DELETE_DRUG_DELETE_TEMP, deleteDrugTemp);
  yield takeLatest(RESTORE_DRUG_DELETE_TEMP, restoreDrugTemp);

}