import React, { memo } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticatedSelector } from "./selector";
import { getItemStorage } from "../../utils/localStore";

const UserRoute = memo(function UserRoute({
  isAuthenticated,
  component: Component,
  ...rest
}) {
  const role = getItemStorage("localStorage", "role");
  return (
    <Route
      {...rest}
      render={
        props => {
          if (isAuthenticated && role !== "Customer") {
            return <Component {...props} />;
          } else {
            if (role === "Customer") {
              return <Redirect to="/guest/danh-sach-cua-hang" />;
            } else {
              return <Redirect to="/" />;
            }
          }
        }
        // (isAuthenticated && role!== "Customer")  ? <Component {...props} /> : (role=== "Customer") ? <Redirect to="/" : <Redirect to="/" />
      }
    />
  );
});

function mapStateToProps(state) {
  return {
    isAuthenticated: !isAuthenticatedSelector(state.get("loginReducer"))
  };
}

export default connect(mapStateToProps)(UserRoute);
